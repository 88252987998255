<template lang='pug'>
    v-container
      validation-observer(v-slot="{ valid }")
        base-material-wizard.mx-auto(v-model="tab" :available-steps="availableSteps" :items="tabs" @click:next="next(valid)" @click:prev="tab--")
          v-icon(aria-label="Close" @click="$emit('close')")
            | mdi-close
          v-tab-item.pb-12
            // First
            form
              .text-center.display-1.font-weight-light.mb-6
                | Your opinion is very important to us. Tell us why you are going
              v-row
                v-col(cols="12" md="12")
                  validation-provider(v-slot="{ errors }" rules="required" name="the reason")
                    v-textarea(v-model="reason" :error-messages="errors" color="secondary" outlined label="Tell us your reasons")
          v-tab-item.pb-12
            // Second
            form
              v-responsive.mx-auto(max-width="500")
                .text-center.display-1.grey--text.font-weight-light.mb-6
                  | Write the name of your actual plan
                .text-center.display-1.grey--text.font-weight-light.mb-6
                  | {{plan.plan.name}}
                v-col(cols="12" md="12")
                  validation-provider(v-slot="{ errors }" rules="required" name="Plan")
                    v-text-field(v-model="namePlan" :error-messages="errors" color="secondary" label="Plan name")
</template>

<script>
  import axios from 'axios'
  import * as vars from '@/vars.json'
  export default {
    name: 'DashboardFormsWizard',

    props: {
      plan: Object,
    },

    data: () => ({
      tab: 0,
      tabs: ['Reason', 'Confirmation'],
      reason: '',
      namePlan: '',
    }),

    computed: {
      scope () {
        if (this.tab === 0) return 'Reason'
        return 'Confirmation'
      },
      availableSteps () {
        const steps = [0]

        if (this.reason) steps.push(1)

        if (this.namePlan === this.plan.plan.name) steps.push(2)

        return steps
      },
    },

    mounted () {

    },

    methods: {
      next (valid) {
        if (!valid) return valid

        if (this.tab === this.tabs.length - 1) {
          this.save()
        } else {
          this.tab++
        }
      },
      save () {
        const vm = this
        const cancel = {
          uid: vm.$store.state.users.user.uid,
          id: vm.plan.id,
          reason: vm.reason,
        }
        // console.log(cancel)
        axios.post(vars.urlProd + '/cancelSubcription', cancel).then(res => {
          // console.log(res)
          vm.$emit('close')
        }).catch(e => {
          // console.log(vm.reason, vm.plan)
        })
      },
    },
  }
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
